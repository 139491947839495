const StarsLeft = ({ className }: { className?: string }) => (
  <svg
    width={47}
    height={57}
    viewBox="0 0 47 57"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{"FB817614-3AF9-4831-ABEF-27774581BECA"}</title>
    <g fillRule="nonzero" fill="none">
      <path
        d="M16.324 56.5a.773.773 0 01-.733-.526c-1.484-4.397-2.595-5.507-6.991-6.991a.774.774 0 010-1.466c4.396-1.484 5.507-2.595 6.99-6.991a.773.773 0 011.466 0c1.484 4.396 2.595 5.507 6.991 6.991a.774.774 0 010 1.466c-4.396 1.484-5.507 2.594-6.99 6.991a.773.773 0 01-.733.526z"
        fill="#FC8ACB"
      />
      <path
        d="M35.456 25.25c-.449 0-.848-.286-.991-.712-2.008-5.948-3.51-7.45-9.459-9.458a1.047 1.047 0 010-1.983c5.948-2.008 7.45-3.51 9.458-9.459a1.046 1.046 0 011.983 0c2.008 5.948 3.51 7.451 9.459 9.459a1.047 1.047 0 010 1.983c-5.949 2.007-7.451 3.51-9.459 9.458a1.045 1.045 0 01-.99.712z"
        fill="#2AD4DB"
      />
      <path
        d="M5.353 10.882a.5.5 0 01-.474-.34C3.919 7.697 3.2 6.978.355 6.018a.5.5 0 010-.948C3.2 4.11 3.919 3.39 4.879.546a.5.5 0 01.948 0c.96 2.845 1.679 3.564 4.524 4.524a.5.5 0 010 .948c-2.845.96-3.564 1.679-4.524 4.524a.5.5 0 01-.474.34z"
        fill="#FECD90"
      />
    </g>
  </svg>
)

export default StarsLeft
