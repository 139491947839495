const Gift = ({ className }: { className?: string }) => (
  <svg
    width={40}
    height={38}
    viewBox="0 0 40 38"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs>
      <linearGradient
        x1="-1.896%"
        y1="80.431%"
        x2="94.404%"
        y2="22.751%"
        id="prefix__a"
      >
        <stop stopColor="#0AE0DE" offset="0%" />
        <stop stopColor="#FE85C9" offset="56.446%" />
        <stop stopColor="#FFD988" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M37.5 10.097h-7.91c.567-.393 1.055-.784 1.395-1.134C33 6.906 33 3.557 30.985 1.5c-1.957-1.999-5.372-2.001-7.332 0-1.083 1.104-3.96 5.596-3.563 8.597h-.18c.395-3.001-2.48-7.493-3.563-8.597-1.96-2.001-5.375-1.999-7.332 0C7 3.557 7 6.906 9.012 8.963c.343.35.83.74 1.398 1.134H2.5c-1.377 0-2.5 1.139-2.5 2.536v6.342c0 .7.56 1.268 1.25 1.268H2.5v15.22C2.5 36.861 3.623 38 5 38h30c1.377 0 2.5-1.139 2.5-2.537v-15.22h1.25c.69 0 1.25-.568 1.25-1.268v-6.342c0-1.397-1.123-2.536-2.5-2.536zM10.788 3.288a2.636 2.636 0 013.787 0c1.685 1.72 3.36 6.114 2.777 6.725 0 0-.105.084-.462.084-1.728 0-5.025-1.821-6.103-2.922a2.796 2.796 0 010-3.887zm7.962 32.175H5v-15.22h13.75v15.22zm0-17.756H2.5v-5.074h16.25v5.074zm6.675-14.419c1.015-1.032 2.775-1.03 3.787 0a2.796 2.796 0 010 3.887c-1.077 1.1-4.375 2.922-6.102 2.922-.357 0-.462-.081-.465-.084-.58-.611 1.095-5.005 2.78-6.725zM35 35.463H21.25v-15.22H35v15.22zm2.5-17.756H21.25v-5.074H37.5v5.074z"
      fill="url(#prefix__a)"
      fillRule="nonzero"
    />
  </svg>
)

export default Gift
