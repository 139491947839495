import { FormEvent, useEffect, useState } from 'react';
import CarouselPage from './components/CarouselPage';
import './App.css';
import Gift from './assets/Gift';
import StarsLeft from './assets/StarsLeft';
import StarsRight from './assets/StarsRight';

import Button from './components/Button';
import Input from './components/Input';
import Select from './components/Select';
import { IUserData } from './interfaces/IUserData';

import { validateEmail, validateWalletPublicAddress } from './utils/validation';
import { insertIntoWhitelist } from './utils/server-api';
import FieldError from './components/FieldError';

const initialData = {
  value: '',
  error: '',
};

const NestedLogo = () => (
  <div className="absolute z-10 block" style={{ top: 20, left: 20 }}>
    <img src={'Logo.svg'} alt="Nested Finance logo" />
  </div>
);

function App() {
  const [country, setCountry] = useState<IUserData>(initialData);
  const [email, setEmail] = useState<IUserData>(initialData);
  const [publicWalletAddress, setPublicWalletAddress] = useState<IUserData>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setFormValid] = useState<boolean>(false);
  const [registrationComplete, setRegistrationComplete] = useState<boolean>(false);
  const [registrationError, setRegistrationError] = useState<string>('');

  useEffect(() => {
    const fromFields = [country, email, publicWalletAddress];
    setFormValid(
      fromFields.every((v) => v.value !== '') &&
        country.value !== 'United States of America (the)' &&
        fromFields.every((v) => v.error === ''),
    );
  }, [country, email, publicWalletAddress]);

  async function onPressSend() {
    let errors = 0;
    setIsLoading(true);

    if (country.value === 'United States of America (the)') {
      setCountry((prev) => ({
        ...prev,
        error: 'U.S Citizens can’t request a beta access',
      }));
      errors += 1;
    }
    if (errors === 0) {
      const ok = await insertIntoWhitelist(email.value, country.value, publicWalletAddress.value);
      if (!ok) {
        setRegistrationError('An error occured while submitting your registration, please try again later.');
        setIsLoading(false);
        return;
      }

      // Reset the form
      setEmail(initialData);
      setCountry(initialData);
      setPublicWalletAddress(initialData);
      setFormValid(false);
      setRegistrationComplete(true);
      setRegistrationError('');
    }
    setIsLoading(false);
  }

  function onFormSubmit(event: FormEvent) {
    event.preventDefault();
  }

  function onChangeEmail(value: string) {
    setEmail({
      error: !validateEmail(value) ? 'This email address is incorrect' : '',
      value,
    });
  }

  function onChangeWalletAddress(value: string) {
    setPublicWalletAddress({
      error: !validateWalletPublicAddress(value) ? 'Invalid address! Only 0x… adrress are allowed' : '',
      value,
    });
  }

  function onChangeCountry(value: string) {
    setCountry({
      error: value === 'United States of America (the)' ? "U.S Citizens can't request a beta access" : '',
      value,
    });
  }

  return (
    <div className="w-screen min-h-full flex md:flex-row flex-col relative">
      <NestedLogo />
      <div
        className="flex flex-col items-center max-w-full w-full order-2 md:order-1 p-4 justify-center flex-1"
        style={{ paddingTop: 80 }}
      >
        <div className="max-w-xl text-xl mb-10 text-center">
          The one-stop Defi app to build, manage and monetize your portfolio
        </div>
        <CarouselPage />
      </div>
      <div className="flex flex-col bg-whiteblue w-full justify-center items-center order-1 md:order-2 p-4 pt-20 md:pt-4 flex-1">
        <div className="flex flex-col text-left max-w-[507px] gap-6">
          <h1 className="mb-1">Request Beta access</h1>
          <span className="font-light text-greytext">
            Please select your country, register your email and your public wallet address.
          </span>
          <div className="h-px bg-rainbow mb-3" />
          <form onSubmit={onFormSubmit}>
            <Select className="mb-7" onChange={onChangeCountry} label="Country of residence" error={country.error} />
            <Input
              className="mb-7"
              disabled={isLoading}
              label="Email"
              onChangeText={onChangeEmail}
              error={email.error}
            />
            <Input
              className="mb-7"
              disabled={isLoading}
              label="Public wallet address"
              onChangeText={onChangeWalletAddress}
              error={publicWalletAddress.error}
            />
            <Button
              className="bg-mainblue text-white w-full text-xl h-[45px] rounded-md"
              disabled={isLoading || !isFormValid}
              label="Send Request"
              onPress={onPressSend}
              registrationComplete={registrationComplete}
            />
            {registrationError && <FieldError>{registrationError}</FieldError>}
          </form>
          {/* <div className="flex justify-center font-light">
            <span className="text-greytext">Already beta-tester ?</span>
            &nbsp;
            TODO: fix app link
            <a href="https://rekt.nested.finance/">
              <span className="text-mainblue">Go to the app</span>
            </a>
          </div> */}
          <div className="bg-white text-darkblue flex rounded-md justify-between items-center px-4 py-3">
            <div className="flex items-center">
              <Gift className="mr-1" />
              <StarsLeft className="mr-1" />
            </div>
            <div>
              A prize pool of <span className="font-bold">20,000.00 NST tokens</span> will be shared between the most
              impactful testers.
            </div>
            <StarsRight className="ml-1" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
