export async function insertIntoWhitelist(
  email: string,
  country: string,
  publicWalletAddress: string,
): Promise<boolean> {
  try {
    const res = await fetch(process.env.REACT_APP_API_URL || '', {
      method: 'POST',
      body: JSON.stringify({
        operationName: 'insertIntoWhitelist',
        query:
          'mutation insertIntoWhitelist($email: String!,$country: String!,$walletAddress: String!) {\n  insertIntoWhitelist(email: $email,country: $country,walletAddress: $walletAddress)\n}\n',
        variables: {
          email,
          country,
          walletAddress: publicWalletAddress,
        },
      }),
      headers: {
        'content-type': 'application/json',
        Origin: process.env.PUBLIC_URL,
      },
    });
    const jsonRes = await res.json();

    if (jsonRes.errors) {
      console.error('An error occured saving the registration', jsonRes.errors);
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.error('An error occured saving the registration', e);
    return false;
  }
}
