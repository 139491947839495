import cls from 'classnames'
import DefaultSelect from 'react-select'

import { countryList } from '../utils/countryList'
import FieldError from './FieldError'

interface ISelectProps {
  onChange(value: string): void;
  label: string;
  error?: string;
  className?: string;
}

const DEFAULT_VALUE = { value: "Select a country", label: "Select a country" }

function Select({ onChange, label, error, className }: ISelectProps) {
  function onSelect(country: { value: string, label: string } | null) {
    onChange(country?.value || '')
  }

  return (
    <div className={className}>
      <label className="text-greytext">{label}</label>
      <DefaultSelect
        onChange={onSelect}
        defaultValue={DEFAULT_VALUE}
        styles={{
          control: () => ({
            border: 'none',
            display: 'flex',
            flexDirection: 'row',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          })
        }}
        className={cls(
          "w-full text-black border bg-white rounded-md px-3.5 py-2 mt-3.5 outline-none appearance-none",
          error ? "border-negvar" : "border-greyback",
          )}
        options={countryList.map(country => ({ value: country, label: country }))}
      />
      <FieldError>
        {error}
      </FieldError>
    </div>
  )
}

export default Select
