import { FC, ButtonHTMLAttributes, FormEvent } from 'react';

interface InputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  error?: string;
  onPress(): void;
  disabled?: boolean;
  registrationComplete?: boolean;
}

const Button: FC<InputProps> = ({ label, error, onPress, disabled, registrationComplete, ...rest }) => {
  // TODO make a loader
  function onSubmit(event: FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    onPress();
  }

  if (registrationComplete) {
    return (
      <div className="rounded p-4 flex justify-between flex-col " style={{ backgroundColor: '#2ED39E23', height: 82 }}>
        <p className="font-medium" style={{ color: '#2ED39E' }}>
          Your Request has been successfully sent!
        </p>
        <p className="font-light" style={{ color: '#2ED39E' }}>
          Our team will be back to you by email soon to confirm your access.
        </p>
      </div>
    );
  }

  return (
    <button
      className=""
      onClick={onPress}
      type="submit"
      onSubmit={onSubmit}
      disabled={disabled}
      style={{ height: 45, backgroundColor: disabled ? 'lightGrey' : undefined }}
      {...rest}
    >
      {label}
    </button>
  );
};

export default Button;
