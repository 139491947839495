const FieldError = ({ children: error }: { children?: string }) => (
  <div
    className="font-light text-sm text-negvar ml-3.5 mt-2.5 h-5"
    style={{ marginTop: 10 }}
  >
    {error}
  </div>
)

export default FieldError
