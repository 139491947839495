import cls from 'classnames'
import { ChangeEvent, FC, InputHTMLAttributes } from 'react'
import FieldError from './FieldError'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  className?: string;
  onChangeText(newValue: string): void
}

const Input: FC<InputProps> = ({
  label,
  error,
  onChangeText,
  className,
  ...rest
}) => {
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    onChangeText(event.target.value)
  }

  return (
    <div className={className}>
      <label className="text-greytext">{label}</label>
      <input
        type="text"
        className={cls(
          "w-full px-4 py-3 mt-3.5 border text-darkblue rounded-md focus:outline-none focus:border-mainblue",
          error ? "border-negvar" : "border-greyback",
        )}
        onChange={onChange}
        {...rest}
      />
      <FieldError>
        {error}
      </FieldError>
    </div>
  )
}

export default Input;
