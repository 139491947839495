import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './CarouselPage.css'
import { Carousel } from 'react-responsive-carousel'

import createScreen from '../assets/create.png';
import editScreen from '../assets/edit.png';
import visualizeScreen from '../assets/visualize.png';
import burnScreen from '../assets/burn.png';
import replicateScreen from '../assets/replicate.png';
import earnScreen from '../assets/earn.png';


const SLIDES = [
  {
    src: createScreen,
    title: "Create",
    subtitle: "Choose your champions and mint your portfolio.",
  },
  {
    src: editScreen,
    title: "Edit",
    subtitle: "Improve your strategy and change your mind at any time.",
  },
  {
    src: visualizeScreen,
    title: "Visualize",
    subtitle: "Enjoy a 360° view of all your positions.",
  },
  {
    src: replicateScreen,
    title: "Replicate",
    subtitle: "Check the portfolio explorer and copy other traders’ portfolios.",
  },
  {
    src: earnScreen,
    title: "Earn",
    subtitle: "Collect your royalties when your portfolio is replicated and every time changes are made by your followers.",
  },
  {
    src: burnScreen,
    title: "Burn your NFT",
    subtitle: "In one click, you will be able to burn your NFT and redeem the underlying value.",
  },
]

const renderIndicator = (
  clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
  isSelected: boolean,
  index: number,
  label: string,
) => (
  <li
    className={`${isSelected ? 'bg-mainblue' : 'bg-greyback'} h-2 w-2 inline-block mx-1 rounded-full`}
    onClick={clickHandler}
    onKeyDown={clickHandler}
    value={index}
    key={index}
    role="button"
    tabIndex={0}
    aria-label={SLIDES[index].title}
    title={SLIDES[index].title}
  />
);

const CarouselPage = () => {
  return (
    <Carousel autoPlay showThumbs={false} showStatus={false} renderIndicator={renderIndicator} className="md:max-w-[563px]">
      {SLIDES.map(elem => (
        <div
          key={elem.title}
          className="flex flex-col items-center mb-16"
        >
          <img className="max-h-[500px] resize" style={{ maxHeight: 500, maxWidth: '100%', width: 'auto', }} src={elem.src} alt={elem.title} />
          <p className="text-xl my-5 text-greytext font-semibold">{elem.title}</p>
          <div
            className="font-light text-greytext"
          >
            {elem.subtitle}
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselPage;
