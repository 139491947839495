const StarsRight = ({ className }: { className?: string }) => (
  <svg
    width={43}
    height={58}
    viewBox="0 0 43 58"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{"97E274B2-5A38-4DB9-9FB5-825ACE1F4804"}</title>
    <g fillRule="nonzero" fill="none">
      <path
        d="M24.375 57.794a1.16 1.16 0 01-1.099-.789c-2.226-6.595-3.892-8.261-10.487-10.487a1.16 1.16 0 010-2.198c6.595-2.226 8.26-3.892 10.487-10.487a1.16 1.16 0 012.198 0c2.226 6.595 3.892 8.261 10.487 10.487a1.16 1.16 0 010 2.198c-6.595 2.226-8.261 3.892-10.487 10.487a1.159 1.159 0 01-1.099.79z"
        fill="#FECD90"
      />
      <path
        d="M5.72 27.176a.5.5 0 01-.473-.34c-.96-2.845-1.68-3.564-4.524-4.524a.5.5 0 010-.948c2.844-.96 3.563-1.679 4.523-4.524a.5.5 0 01.949 0c.96 2.845 1.679 3.564 4.523 4.524a.5.5 0 010 .948c-2.844.96-3.563 1.68-4.523 4.524a.5.5 0 01-.474.34z"
        fill="#FE95BC"
      />
      <path
        d="M34.03 16.324a.75.75 0 01-.711-.51c-1.439-4.264-2.516-5.341-6.78-6.78a.75.75 0 010-1.421c4.264-1.439 5.34-2.516 6.78-6.78a.75.75 0 011.42 0c1.44 4.264 2.517 5.341 6.78 6.78a.75.75 0 010 1.421c-4.263 1.439-5.34 2.516-6.78 6.78a.75.75 0 01-.71.51z"
        fill="#2AD4DB"
      />
    </g>
  </svg>
)

export default StarsRight
